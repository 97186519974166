import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Paragraph } from '@entur/typography';
import { BannerAlertBox } from '@entur/alert';
import { NavigationCard } from '@entur/layout';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CheckIcon = makeShortcode("CheckIcon");
const CityIcon = makeShortcode("CityIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "real-time-data"
    }}>{`Real-Time Data`}</h1>
    <p>{`These APIs allow your server to subscribe to real-time data from all included real-time feeds in Norway on SIRI 2.0. It is available as SIRI XML, SIRI Lite (REST) and GTFS-RT.`}</p>
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">
    <Paragraph mdxType="Paragraph">
        These services include only real-time data and not any basic journey
        planner information. If you are creating an end-user service such as a
        journey planner, the{' '}
        <a href="/pages-journeyplanner-journeyplanner">JourneyPlanner API</a> is
        a better choice.
    </Paragraph>
    </BannerAlertBox>
    <h2 {...{
      "id": "available-data-streams"
    }}>{`Available data streams`}</h2>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
        <TableRow mdxType="TableRow">
            <HeaderCell colSpan="2" mdxType="HeaderCell" />
            <HeaderCell colSpan="3" mdxType="HeaderCell">SIRI</HeaderCell>
            <HeaderCell colSpan="3" mdxType="HeaderCell">GTFS-RT</HeaderCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <HeaderCell mdxType="HeaderCell">Codespace</HeaderCell>
            <HeaderCell mdxType="HeaderCell">Source</HeaderCell>
            <HeaderCell mdxType="HeaderCell">ET</HeaderCell>
            <HeaderCell mdxType="HeaderCell">VM</HeaderCell>
            <HeaderCell mdxType="HeaderCell">SX</HeaderCell>
            <HeaderCell mdxType="HeaderCell">Trip Updates</HeaderCell>
            <HeaderCell mdxType="HeaderCell">Vehicle Positions</HeaderCell>
            <HeaderCell mdxType="HeaderCell">Service Alerts</HeaderCell>
        </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">Entire Norway</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">AKT</DataCell>
            <DataCell mdxType="DataCell">Agder (AKT)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">ATB</DataCell>
            <DataCell mdxType="DataCell">Trøndelag (AtB)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">AVI</DataCell>
            <DataCell mdxType="DataCell">Avinor</DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">BNR</DataCell>
            <DataCell mdxType="DataCell">Vy/Go-Ahead/SJ Nord (via Bane NOR)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">BRA</DataCell>
            <DataCell mdxType="DataCell">Viken (Brakar)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">FIN</DataCell>
            <DataCell mdxType="DataCell">Troms og Finnmark (Snelandia)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">FLT</DataCell>
            <DataCell mdxType="DataCell">Flytoget</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">GJB</DataCell>
            <DataCell mdxType="DataCell">Vy (formerly NSB) Gjøvikbanen</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">GOA</DataCell>
            <DataCell mdxType="DataCell">Go-Ahead</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">INN</DataCell>
            <DataCell mdxType="DataCell">Innlandet (Innlandstrafikk)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">KOL</DataCell>
            <DataCell mdxType="DataCell">Rogaland (Kolumbus)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">MOR</DataCell>
            <DataCell mdxType="DataCell">Møre og Romsdal (Fram)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">NBU</DataCell>
            <DataCell mdxType="DataCell">Connect Bus Flybuss</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">NOR</DataCell>
            <DataCell mdxType="DataCell">Nordland fylkeskommune</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">NSB</DataCell>
            <DataCell mdxType="DataCell">Vy</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">OST</DataCell>
            <DataCell mdxType="DataCell">Viken (Østfold kollektivtrafikk)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">RUT</DataCell>
            <DataCell mdxType="DataCell">Oslo region (Ruter)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">SJN</DataCell>
            <DataCell mdxType="DataCell">SJ Nord</DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">SKY</DataCell>
            <DataCell mdxType="DataCell">Vestland (Skyss)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">SOF</DataCell>
            <DataCell mdxType="DataCell">Vestland (Kringom)</DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">TEL</DataCell>
            <DataCell mdxType="DataCell">Vestfold og Telemark (Farte)</DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">TRO</DataCell>
            <DataCell mdxType="DataCell">Troms og Finnmark (Troms fylkestrafikk)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">VKT</DataCell>
            <DataCell mdxType="DataCell">Vestfold og Telemark (VKT)</DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">VOT</DataCell>
            <DataCell mdxType="DataCell">Vestfold og Telemark</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">VYB</DataCell>
            <DataCell mdxType="DataCell">Vy Buss (SE)</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">VYG</DataCell>
            <DataCell mdxType="DataCell">Vy Group</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <DataCell mdxType="DataCell">VYX</DataCell>
            <DataCell mdxType="DataCell">Vy Express</DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell"> </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
            <DataCell mdxType="DataCell">
                <CheckIcon mdxType="CheckIcon" />
            </DataCell>
        </TableRow>
    </TableBody>
    </Table>
    <h2 {...{
      "id": "siri"
    }}>{`SIRI`}</h2>
    <p>{`SIRI is a CEN Technical Standard that specifies a European interface standard for exchanging information about the planned, current or projected performance of real-time public transport operations between different computer systems.`}</p>
    <h3 {...{
      "id": "publishsubscribe"
    }}>{`Publish/subscribe`}</h3>
    <p>{`When using `}<inlineCode parentName="p">{`publish/subscribe`}</inlineCode>{` you'll first get one (or more) set with initial data, and then continuous updates with changes. This option requires an accessible endpoint which accepts HTTP POST requests from the Entur SIRI service.`}</p>
    <h3 {...{
      "id": "requestresponse"
    }}>{`Request/response`}</h3>
    <p>{`For Request/Response, the `}<inlineCode parentName="p">{`<RequestorRef>`}</inlineCode>{` attribute is used to track changes and must therefore remain the same for all subsequent requests in order to only get changes. The attribute `}<inlineCode parentName="p">{`MoreData=true`}</inlineCode>{` means the data comes in bunches, just like paging, and more data can be fetched immediately after the first "page" is received. Eg. if there are 20,000 objects, these will be split up in smaller parts and repeated requests are needed to get the full set.`}</p>
    <h3 {...{
      "id": "types-of-data"
    }}>{`Types of data`}</h3>
    <h4 {...{
      "id": "et--estimated-timetable"
    }}>{`ET – Estimated Timetable`}</h4>
    <p>{`Real-time estimates for each service journey. Included are planned-, actual- and estimated departure times, cancellations and changes in stop patterns.`}</p>
    <h4 {...{
      "id": "vm--vehicle-monitoring"
    }}>{`VM – Vehicle Monitoring`}</h4>
    <p>{`Vehicle-centric real-time data with the position of a vehicle, as well as any deviation from scheduled timetable on a given departure.`}</p>
    <h4 {...{
      "id": "sx--situation-exchange"
    }}>{`SX – Situation Exchange`}</h4>
    <p>{`Textual messages displayed to end users. They are always associated with a departure, line, stop etc., or combinations of these.`}</p>
    <h3 {...{
      "id": "siri-lite"
    }}>{`SIRI Lite`}</h3>
    <p>{`With simple HTTP GET calls to retrieve data, you can use the `}<inlineCode parentName="p">{`requestorId`}</inlineCode>{` URL-parameter to `}<em parentName="p">{`only`}</em>{` retrieve changes made since the last time you asked. If you do not use this, you will always get the full dataset, not just the first time you make a request. You must use a unique `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Universally_unique_identifier"
      }}>{`UUID`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`Example: `}<a parentName="li" {...{
          "href": "https://api.entur.io/realtime/v1/rest/sx?requestorId=757c9284-0f12-4a46-a557-33a19e80119b"
        }}>{`https://api.entur.io/realtime/v1/rest/sx?requestorId=757c9284-0f12-4a46-a557-33a19e80119b`}</a></li>
    </ul>
    <h3 {...{
      "id": "more-information"
    }}>{`More information`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`SIRI standard: `}<a parentName="p" {...{
            "href": "https://transmodel-cen.eu/index.php/siri/"
          }}>{`https://transmodel-cen.eu/index.php/siri/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Norwegian SIRI-profile: `}<a parentName="p" {...{
            "href": "https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/637370420/Norwegian+SIRI+profile"
          }}>{`https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/637370420/Norwegian+SIRI+profile`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example SIRI-XML that follow the Norwegian SIRI-profile: `}<a parentName="p" {...{
            "href": "https://github.com/entur/profile-norway-examples/tree/master/siri/"
          }}>{`https://github.com/entur/profile-norway-examples/tree/master/siri/`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "gtfs-rt"
    }}>{`GTFS-RT`}</h2>
    <p>{`GTFS Realtime is a feed specification that allows public transportation agencies to provide realtime updates about their fleet to application developers. It is an extension to GTFS (General Transit Feed Specification), an open data format for public transportation schedules and associated geographic information.`}</p>
    <h3 {...{
      "id": "requestresponse-1"
    }}>{`Request/response`}</h3>
    <p>{`Only supports request/response currently. Endpoints exist for each data type that download the full dataset, filtered by datasource (codespace) if provided. This dataset is updated every 15 seconds. Later we will support publish/subscribe and continuous updates.`}</p>
    <ul>
      <li parentName="ul">{`Example: `}<a parentName="li" {...{
          "href": "https://api.entur.io/realtime/v1/gtfs-rt/trip-updates?datasource=RUT"
        }}>{`https://api.entur.io/realtime/v1/gtfs-rt/trip-updates?datasource=RUT`}</a></li>
    </ul>
    <h3 {...{
      "id": "types-of-data-1"
    }}>{`Types of data`}</h3>
    <h4 {...{
      "id": "trip-updates"
    }}>{`Trip updates`}</h4>
    <p>{`Delays, cancellations, changed routes. Similar to SIRI ET.`}</p>
    <h4 {...{
      "id": "vehicle-positions"
    }}>{`Vehicle positions`}</h4>
    <p>{`Information about the vehicles including location and congestion level. Similar to SIRI VM.`}</p>
    <h4 {...{
      "id": "service-alerts"
    }}>{`Service alerts`}</h4>
    <p>{`Stop moved, unforeseen events affecting a station, route or the entire network. Similar to SIRI SX.`}</p>
    <h3 {...{
      "id": "more-information-1"
    }}>{`More information`}</h3>
    <ul>
      <li parentName="ul">{`GTFS-RT documentation: `}<a parentName="li" {...{
          "href": "https://gtfs.org/documentation/realtime/reference/"
        }}>{`https://gtfs.org/documentation/realtime/reference/`}</a><br /></li>
      <li parentName="ul">{`Trip updates example: `}<a parentName="li" {...{
          "href": "https://gtfs.org/documentation/realtime/examples/trip-updates/"
        }}>{`https://gtfs.org/documentation/realtime/examples/trip-updates/`}</a></li>
    </ul>
    <div style={{
      marginTop: '4rem'
    }}>
    <NavigationCard title="Road status, incidents, public transport and schedules" href=" https://transportportal.no/en" target="_blank" titleIcon={<CityIcon mdxType="CityIcon" />} mdxType="NavigationCard">
        The portal’s data catalogue gives you descriptions and links to data
        sets and services with information on road status, incidents, public
        transport, schedules and much more. All data sets are open and available
        for re-use and as source for your own services.
    </NavigationCard>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      